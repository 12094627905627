<template>
  <v-dialog
    v-model="dialog"
    max-width="700"
    :retain-focus="false"
    hide-overlay
    no-click-animation
  >
    <template v-slot:activator="{ on, attrs }">
      <slot name="activator" :on="on" :attrs="attrs" />
    </template>
    <v-form
      ref="form"
      v-model="isValid"
      :disabled="isLoading"
    >
      <v-card
        min-height="200"
      >
        <v-card-title
          class="
          draggable
          bg-ease
          white--text
          px-5
          py-1
        "
          style="font-size: 1rem;"
        >
          <v-icon left small dark>
            mdi-web
          </v-icon>
          {{ $t('inverse distance') }}

          <v-spacer />

          <v-btn
            dark
            icon
            small
            @click="cancel"
          >
            <v-icon small>
              mdi-close
            </v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text class="pt-3">
          <v-row
            no-gutters
          >
            <v-col
              class="label-col"
              :class="[$i18n.locale]"
              cols="auto"
            >
              <v-subheader class="align-start px-0">
                {{ $t('select_model_core') }}
              </v-subheader>
            </v-col>
            <v-col cols="auto">
              <v-select
                v-model="formData.image"
                :items="modelImages"
                :rules="rulesModelIamge"
                dense
                outlined
                hide-details="auto"
              >
                <template v-slot:selection="{ item }">
                  {{ $t(item.text) }}
                </template>
                <template v-slot:item="{ item }">
                  {{ $t(item.text) }}
                </template>
              </v-select>
            </v-col>
          </v-row>
          <!-- points -->
          <v-row
            class="mt-6"
            no-gutters
          >
            <v-col
              class="label-col"
              :class="[$i18n.locale]"
              cols="auto"
            >
              <v-subheader class="align-start px-0">
                {{ $t('page.interpolating.select_points_file') }}
              </v-subheader>
            </v-col>
            <v-col cols="auto">
              <v-input
                :value="formData.points"
                :rules="rulesFile"
                hide-details="auto"
              >
                <FileSelect v-model="formData.points">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      v-bind="attrs"
                      color="primary"
                      small
                      :disabled="isLoading"
                      v-on="on"
                    >
                      {{ $t('select') }}
                    </v-btn>
                  </template>
                </FileSelect>
              </v-input>
            </v-col>
            <v-col cols="12">
              <v-text-field
                v-if="formData.points"
                class="flex-nowrap"
                color="primary"
                readonly
                filled
                single-line
              >
                <template v-slot:label>
                  <template v-for="(breadcrumb, iBreadcrumb) in pointsBreadcrumbs">
                    <span
                      :key="iBreadcrumb"
                      class="text-no-wrap primary--text"
                      style="font-size: 0.9rem;"
                    >{{ breadcrumb }}</span>
                    <v-icon
                      v-if="iBreadcrumb < pointsBreadcrumbs.length - 1"
                      :key="`${iBreadcrumb}-divder`"
                      color="primary"
                      small
                    >
                      mdi-chevron-right
                    </v-icon>
                  </template>
                </template>
                <template v-slot:append>
                  <v-btn
                    color="error"
                    icon small
                    :disabled="isLoading"
                    @click="formData.points = null"
                  >
                    <v-icon>mdi-close</v-icon>
                  </v-btn>
                </template>
              </v-text-field>
            </v-col>
          </v-row>
          <!-- range -->
          <v-row
            class="mt-6"
            no-gutters
          >
            <v-col
              class="label-col"
              :class="[$i18n.locale]"
              cols="auto"
            >
              <v-subheader class="align-start px-0">
                {{ $t('page.interpolating.select_rang_file') }}
              </v-subheader>
            </v-col>
            <v-col cols="auto">
              <v-input
                :value="formData.range"
                :rules="rulesFile"
                hide-details="auto"
              >
                <FileSelect v-model="formData.range">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      v-bind="attrs"
                      color="primary"
                      small
                      :disabled="isLoading"
                      v-on="on"
                    >
                      {{ $t('select') }}
                    </v-btn>
                  </template>
                </FileSelect>
              </v-input>
            </v-col>
            <v-col cols="12">
              <v-text-field
                v-if="formData.range"
                class="flex-nowrap"
                color="primary"
                readonly
                filled
                single-line
              >
                <template v-slot:label>
                  <template v-for="(breadcrumb, iBreadcrumb) in rangeBreadcrumbs">
                    <span
                      :key="iBreadcrumb"
                      class="text-no-wrap primary--text"
                      style="font-size: 0.9rem;"
                    >{{ breadcrumb }}</span>
                    <v-icon
                      v-if="iBreadcrumb < rangeBreadcrumbs.length - 1"
                      :key="`${iBreadcrumb}-divder`"
                      color="primary"
                      small
                    >
                      mdi-chevron-right
                    </v-icon>
                  </template>
                </template>
                <template v-slot:append>
                  <v-btn
                    color="error"
                    icon small
                    :disabled="isLoading"
                    @click="formData.range = null"
                  >
                    <v-icon>mdi-close</v-icon>
                  </v-btn>
                </template>
              </v-text-field>
            </v-col>
          </v-row>

          <!-- params -->
          <v-row
            class="mt-6"
            no-gutters
          >
            <v-col
              class="label-col"
              :class="[$i18n.locale]"
              cols="auto"
            >
              <v-subheader class="align-start px-0">
                {{ $t('page.interpolating.select_params_file') }}
              </v-subheader>
            </v-col>
            <v-col cols="auto">
              <v-input
                :value="formData.params"
                :rules="rulesParams"
                hide-details="auto"
              >
                <FileSelect
                  v-model="formData.params"
                  :simulation-model-uuid="model"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      v-bind="attrs"
                      color="primary"
                      small
                      :disabled="isLoading"
                      v-on="on"
                    >
                      {{ $t('select') }}
                    </v-btn>
                  </template>
                </FileSelect>
              </v-input>
            </v-col>
            <v-col cols="12">
              <v-text-field
                v-if="formData.params"
                class="flex-nowrap"
                color="primary"
                readonly
                filled
                single-line
              >
                <template v-slot:label>
                  <template v-for="(breadcrumb, iBreadcrumb) in paramsBreadcrumbs">
                    <span
                      :key="iBreadcrumb"
                      class="text-no-wrap primary--text"
                      style="font-size: 0.9rem;"
                    >{{ breadcrumb }}</span>
                    <v-icon
                      v-if="iBreadcrumb < paramsBreadcrumbs.length - 1"
                      :key="`${iBreadcrumb}-divder`"
                      color="primary"
                      small
                    >
                      mdi-chevron-right
                    </v-icon>
                  </template>
                </template>
                <template v-slot:append>
                  <v-btn
                    color="error"
                    icon small
                    :disabled="isLoading"
                    @click="formData.params = null"
                  >
                    <v-icon>mdi-close</v-icon>
                  </v-btn>
                </template>
              </v-text-field>
            </v-col>
          </v-row>

          <!-- output mapset -->
          <v-row
            class="mt-6"
            no-gutters
          >
            <v-col
              class="label-col"
              :class="[$i18n.locale]"
              cols="auto"
            >
              <v-subheader class="align-start px-0">
                {{ $t('page.interpolating.select_output_mapset') }}
              </v-subheader>
            </v-col>
            <v-col cols="auto">
              <v-input
                :value="formData.mapset"
                :rules="rulesFile"
                hide-details="auto"
              >
                <FileSelect
                  v-model="formData.mapset"
                  :select-file-type="FILE_TYPE.MAPSET"
                  :permissions="['create']"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      v-bind="attrs"
                      color="primary"
                      small
                      :disabled="isLoading"
                      v-on="on"
                    >
                      {{ $t('select') }}
                    </v-btn>
                  </template>
                </FileSelect>
              </v-input>
            </v-col>
            <v-col cols="12">
              <v-text-field
                v-if="formData.mapset"
                class="flex-nowrap"
                color="primary"
                readonly
                filled
                single-line
              >
                <template v-slot:label>
                  <template v-for="(breadcrumb, iBreadcrumb) in mapsetBreadcrumbs">
                    <span
                      :key="iBreadcrumb"
                      class="text-no-wrap primary--text"
                      style="font-size: 0.9rem;"
                    >{{ breadcrumb }}</span>
                    <v-icon
                      v-if="iBreadcrumb < mapsetBreadcrumbs.length - 1"
                      :key="`${iBreadcrumb}-divder`"
                      color="primary"
                      small
                    >
                      mdi-chevron-right
                    </v-icon>
                  </template>
                </template>
                <template v-slot:append>
                  <v-btn
                    color="error"
                    icon small
                    :disabled="isLoading"
                    @click="formData.mapset = null"
                  >
                    <v-icon>mdi-close</v-icon>
                  </v-btn>
                </template>
              </v-text-field>
            </v-col>
          </v-row>
        </v-card-text>

        <v-card-actions class="pa-5">
          <v-spacer />

          <v-btn
            class="action-btn"
            rounded
            :disabled="isLoading"
            @click="cancel"
          >
            {{ $t('close') }}
          </v-btn>
          <v-btn
            class="action-btn"
            rounded
            color="primary"
            :loading="isLoading"
            @click="submit"
          >
            {{ $t('start') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import FileSelect from '@/components/FileSelect/FileSelect'
import { validateRequired } from '@/assets/js/validate'

import { SIM_MODEL } from '@/models'
import { FILE_TYPE } from '@/models/utils'

import { mapGetters, mapState } from 'vuex'

export default {
  name: 'InterpolatingDialog',

  components: {
    FileSelect
  },

  props: {
    value: {
      type: Boolean,
      default: false
    }
  },

  data: vm => ({
    FILE_TYPE,
    SIM_MODEL,
    show: false,
    isValid: false,
    isLoading: false,
    formData: {
      image: vm.modelImages?.[0]?.value,
      points: null,
      range: null,
      params: null,
      mapset: null
    },
    rulesModelIamge: [validateRequired],
    rulesFile: [validateRequired],
    rulesParams: [validateRequired]
  }),

  computed: {
    ...mapState({
      model: state => state.simulation.model
    }),
    ...mapGetters({
      getProjectByRoute: 'projects/getProjectByRoute',
      modelImages: 'simulation/modelImages'
    }),

    dialog: {
      get() {
        return this.show
      },
      set(newVal) {
        this.show = newVal
        this.$emit('input', newVal)
      }
    },

    project() {
      return this.getProjectByRoute(this.$route)
    },
    pointsBreadcrumbs() {
      const points = this.formData.points

      if (!points) {
        return []
      }

      return points.breadcrumbs
    },
    rangeBreadcrumbs() {
      const range = this.formData.range

      if (!range) {
        return []
      }

      return range.breadcrumbs
    },
    paramsBreadcrumbs() {
      const params = this.formData.params

      if (!params) {
        return []
      }

      return params.breadcrumbs
    },
    mapsetBreadcrumbs() {
      const mapset = this.formData.mapset

      if (!mapset) {
        return []
      }

      return mapset.breadcrumbs
    }
  },

  watch: {
    value: {
      handler(newVal) {
        this.show = newVal
      },
      immediate: true
    },
    'formData.points'(newVal, oldVal) {
      if (!newVal || newVal?.s3Uuid !== oldVal?.s3Uuid) {
        this.formData.params = null
      }
    },
    '$route.params.lang'(newVal, oldVal) {
      if (newVal !== oldVal && this.$refs.form) {
        this.$refs.form.validate()
      }
    }
  },

  methods: {
    close() {
      this.dialog = false
    },
    cancel() {
      this.close()
    },
    async submit() {
      await this.$refs.form.validate()

      if (!this.isValid) {
        return
      }

      this.isLoading = true
      return this.$store.dispatch('simulation/interpolating/start', {
        project: this.project,
        ...this.formData
      })
        .then(() => {
          this.$store.dispatch('snackbar/showSuccess', {
            content: this.$t('success_simulation')
          })
        })
        .catch(error => {
          this.$store.dispatch('snackbar/showError', {
            content: error
          })
        })
        .finally(() => {
          this.isLoading = false
        })
    }
  }
}
</script>

<style lang="scss" scoped>
.v-btn.v-btn.action-btn {
  padding: 0.286em 1.743em;
  height: unset;
  font-size: 0.875rem;
  box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.2);
  letter-spacing: normal;
}

.label-col {
  width: 11em;

  &.en {
    width: 12em;
  }
}
</style>
