<template>
  <v-form
    ref="form"
    @submit.prevent
  >
    <v-row
      class="geo-params-row"
      no-gutters
      align="start"
    >
      <v-col class="file-col">
        <v-input
          :value="geoFile"
          :rules="rulesGeoFile"
          hide-details="auto"
        >
          <FileSelect
            v-model="geoFile"
            :simulation-model-uuid="model"
            filter-parent-maplayer
          >
            <template
              v-slot:activator="{ on, attrs }"
            >
              <v-badge
                class="secondary--text"
                left
                color="transparent"
                content="geo"
                overlap
                offset-x="2em"
                style="max-width: 100%;"
              >
                <v-chip
                  v-bind="attrs"
                  color="grey lighten-3"
                  v-on="on"
                >
                  <v-tooltip
                    v-if="geoFile"
                    top
                  >
                    <template v-slot:activator="{ on:onTooltip, attrs:attrsTooltip }">
                      <div
                        v-bind="attrsTooltip"
                        class="text-truncate"
                        v-on="onTooltip"
                      >
                        {{ geoFile.name }}
                      </div>
                    </template>

                    <template v-for="(breadcrumb, iBreadcrumb) in geoFile.breadcrumbs">
                      <span
                        :key="iBreadcrumb"
                        class="text-no-wrap"
                      >{{ breadcrumb }}</span>
                      <v-icon
                        v-if="iBreadcrumb < geoFile.breadcrumbs.length - 1"
                        :key="`${iBreadcrumb}-divder`"
                        dark
                        small
                      >
                        mdi-chevron-right
                      </v-icon>
                    </template>
                  </v-tooltip>
                  <div v-else>
                    {{ $t('select') }}
                  </div>
                </v-chip>
              </v-badge>
            </template>
          </FileSelect>
        </v-input>
      </v-col>
      <v-col class="file-col">
        <v-input
          :value="paramFile"
          :rules="rulesParamFile"
          hide-details="auto"
        >
          <FileSelect
            v-model="paramFile"
            :simulation-model-uuid="model"
            :parent-maplayer-uuid="geoFile && geoFile.s3Uuid"
            filter-parent-maplayer
          >
            <template
              v-slot:activator="{ on, attrs }"
            >
              <v-badge
                class="secondary--text"
                left
                color="transparent"
                content="param"
                overlap
                offset-x="3em"
                style="max-width: 100%;"
              >
                <v-chip
                  v-bind="attrs"
                  :disabled="!geoFile"
                  color="grey lighten-3"
                  v-on="on"
                >
                  <v-tooltip
                    v-if="paramFile"
                    top
                  >
                    <template v-slot:activator="{ on:onTooltip, attrs:attrsTooltip }">
                      <div
                        v-bind="attrsTooltip"
                        class="text-truncate"
                        v-on="onTooltip"
                      >
                        {{ paramFile.name }}
                      </div>
                    </template>

                    <template v-for="(breadcrumb, iBreadcrumb) in paramFile.breadcrumbs">
                      <span
                        :key="iBreadcrumb"
                        class="text-no-wrap"
                      >{{ breadcrumb }}</span>
                      <v-icon
                        v-if="iBreadcrumb < paramFile.breadcrumbs.length - 1"
                        :key="`${iBreadcrumb}-divder`"
                        dark
                        small
                      >
                        mdi-chevron-right
                      </v-icon>
                    </template>
                  </v-tooltip>
                  <div v-else>
                    {{ $t('select') }}
                  </div>
                </v-chip>
              </v-badge>
            </template>
          </FileSelect>
        </v-input>
      </v-col>
      <v-spacer />
      <v-col
        class="align-self-center"
        cols="auto"
      >
        <v-btn
          icon
          color="error"
          x-small
          @click="remove"
        >
          <v-icon>mdi-delete</v-icon>
        </v-btn>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import FileSelect from '@/components/FileSelect/FileSelect.vue'

import { validateRequired } from '@/assets/js/validate'

import { mapState } from 'vuex'

export default {
  name: 'GeoParamsSelect',

  components: {
    FileSelect
  },

  props: {
    geojsonItem: {
      type: Object,
      required: true
    }
  },

  data: () => ({
    rulesGeoFile: [validateRequired],
    rulesParamFile: [validateRequired]
  }),

  computed: {
    ...mapState({
      model: state => state.simulation.modelm
    }),

    geoFile: {
      get() {
        return this.geojsonItem.geoFile
      },
      set(newVal) {
        this.$set(this.geojsonItem, 'geoFile', newVal)
        this.paramFile = null
      }
    },
    paramFile: {
      get() {
        return this.geojsonItem.paramFile
      },
      set(newVal) {
        this.$set(this.geojsonItem, 'paramFile', newVal)
      }
    }
  },

  methods: {
    remove() {
      this.$emit('removeGeojsonItem')
    },
    validate() {
      if (!this.$refs.form) {
        return true
      }
      return this.$refs.form.validate()
    },
    resetValidation() {
      if (!this.$refs.form) {
        return
      }

      return this.$refs.form.resetValidation()
    }
  }
}
</script>

<style lang="scss" scoped>
.geo-params-row {
  padding: 0.5em;

  .file-col {
    max-width: 45%;
    flex-basis: 45%;
  }
}

.v-badge {
  ::v-deep .v-badge__badge {
    color: unset;
  }
}
</style>
