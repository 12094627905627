var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-navigation-drawer',{staticClass:"map-bar",attrs:{"app":""}},[_c('v-list-item',{staticClass:"px-0"},[_c('v-list-item-content',{staticClass:"py-0",staticStyle:{"height":"48px"}},[(_vm.userName)?_c('UserAvatar',{scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"bg-ease",attrs:{"dark":"","text":"","tile":"","x-large":"","height":"100%"}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"left":""}},[_vm._v(" mdi-account-circle ")]),_vm._v(" "+_vm._s(_vm.userName)+" ")],1)]}}],null,false,1465397652)}):_vm._e()],1)],1),_c('v-list-item',[_c('v-list-item-content',[_c('TranslationMenu',{scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"text":"","tile":"","large":"","height":"100%"}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" mdi-translate ")])],1)]}}])})],1)],1),_c('v-list-item',[_c('v-list-item-content',[(_vm.isMapModel)?_c('v-btn',{attrs:{"text":"","tile":"","x-large":"","height":"100%","to":{
          name: 'MapViewer',
          params: _vm.$route.params,
          query: _vm.$route.query
        }}},[_c('v-icon',{attrs:{"left":""}},[_vm._v(" mdi-eye ")]),_vm._v(" "+_vm._s(_vm.$t('viewer_model'))+" ")],1):_vm._e()],1)],1),_c('v-list-item',[_c('v-list-item-content',[_c('v-spacer')],1)],1),_c('v-list-item',[_c('v-list-item-content',[_c('FileManagerDialog',_vm._g({scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"text":"","tile":"","x-large":"","height":"100%","exact":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"left":""}},[_vm._v(" mdi-folder-outline ")]),_vm._v(" "+_vm._s(_vm.$t('file_manager'))+" ")],1)]}}])},_vm.$listeners))],1)],1),_c('v-list-item',[_c('v-list-item-content',[_c('SimulationDialog',_vm._g({scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticStyle:{"width":"100%"},attrs:{"text":"","tile":"","x-large":"","height":"100%","exact":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"left":""}},[_vm._v(" mdi-chevron-triple-right ")]),_vm._v(" "+_vm._s(_vm.$t('simulation'))+" ")],1)]}}])},_vm.$listeners))],1)],1),_c('v-list-item',[_c('v-list-item-content',[_c('v-btn',{attrs:{"text":"","tile":"","x-large":"","height":"100%","exact":"","disabled":!_vm.layerNodes.length,"to":{
          name: 'MapPostprocess',
          params: _vm.$route.params
        }},on:{"click":function($event){return _vm.initPostprocess()}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v(" mdi-checkerboard-plus ")]),_vm._v(" "+_vm._s(_vm.$t('post_processing_tools'))+" ")],1)],1)],1),_c('v-list-item',[_c('v-list-item-content',[_c('v-btn',{attrs:{"text":"","tile":"","x-large":"","height":"100%","to":{
          name:'MapDrawer',
          params: _vm.$route.params,
          query: _vm.$route.query
        }}},[_c('v-icon',{attrs:{"left":""}},[_vm._v(" mdi-pencil-ruler ")]),_vm._v(" "+_vm._s(_vm.$t('hand_drawn_tools'))+" ")],1)],1)],1),_c('v-spacer'),_c('v-toolbar-title',{staticClass:"mx-11 d-flex align-center",staticStyle:{"min-width":"40px"}},[_c('v-btn',{staticStyle:{"width":"100%"},attrs:{"color":"primary","rounded":""},on:{"click":_vm.toHomePage}},[_c('v-icon',{attrs:{"left":""}},[_vm._v(" mdi-home-variant-outline ")]),_vm._v(" "+_vm._s(_vm.$t('back_to_home'))+" ")],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }