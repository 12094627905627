<template>
  <v-form
    ref="form"
    class="massbalance-output-form"
    @submit.prevent
  >
    <v-input
      :value="mapsetUuid"
      :rules="rulesFile"
      hide-details="auto"
    >
      <v-subheader class="px-0">
        {{ $t('page.massbalance.select_output_mapset') }}
        <FileSelect
          v-model="mapsetUuid"
          :select-file-type="FILE_TYPE.MAPSET"
          :permissions="['create']"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              class="ml-8"
              color="primary"
              small
              v-on="on"
            >
              {{ $t('select') }}
            </v-btn>
          </template>
        </FileSelect>
      </v-subheader>
    </v-input>
    <v-text-field
      v-if="mapsetUuid"
      class="flex-nowrap"
      color="primary"
      readonly
      filled
      single-line
    >
      <template v-slot:label>
        <template v-for="(breadcrumb, iBreadcrumb) in mapsetUuid.breadcrumbs">
          <span
            :key="iBreadcrumb"
            class="text-no-wrap primary--text"
            style="font-size: 0.9rem;"
          >{{ breadcrumb }}</span>
          <v-icon
            v-if="iBreadcrumb < mapsetUuid.breadcrumbs.length - 1"
            :key="`${iBreadcrumb}-divder`"
            color="primary"
            small
          >
            mdi-chevron-right
          </v-icon>
        </template>
      </template>
      <template v-slot:append>
        <v-btn
          color="error"
          icon small
          @click="mapsetUuid = null"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>
    </v-text-field>
  </v-form>
</template>

<script>
import FileSelect from '@/components/FileSelect/FileSelect'

import { FILE_TYPE } from '@/models/utils'

import { validateRequired } from '@/assets/js/validate'

import { mapState } from 'vuex'

export default {
  name: 'MassbalanceOutputs',

  components: {
    FileSelect
  },

  data: () => ({
    FILE_TYPE,
    rulesFile: [validateRequired]
  }),

  computed: {
    ...mapState({
      model: state => state.simulation.model,
      simData: state => state.simulation.massbalance.simData
    }),

    mapsetUuid: {
      get() {
        return this.simData.output.mapsetUuid
      },
      set(newVal) {
        this.$set(this.simData.output, 'mapsetUuid', newVal)
      }
    }
  },

  methods: {
    validate() {
      if (!this.$refs.form) {
        return true
      }
      return this.$refs.form.validate()
    },
    resetValidation() {
      if (!this.$refs.form) {
        return
      }

      return this.$refs.form.resetValidation()
    }
  }
}
</script>

<style lang="scss" scoped>
.massbalance-output-form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;

  & > div {
    flex-grow: 0;
  }
}
</style>
