<template>
  <div style="height: 100%;">
    <slot name="activator" :on="vOn">
      <v-btn v-on="vOn">
        {{ $t('simulation') }}
      </v-btn>
    </slot>

    <InterpolatingDialog
      v-if="model === SIM_MODEL.INTERPOLATING"
      v-model="dialog"
    />
    <ModflowDialog
      v-if="
        [
          SIM_MODEL.MODFLOW,
          SIM_MODEL.FEMWATER_MESH
        ].includes(model)
      "
      v-model="dialog"
    />
    <FemwaterDialog
      v-if="model === SIM_MODEL.FEMWATER"
      v-model="dialog"
    />
    <MassbalanceDialog
      v-if="model === SIM_MODEL.MASSBALANCE"
      v-model="dialog"
      v-on="$listeners"
    />
  </div>
</template>

<script>
import InterpolatingDialog from './Interpolating/InterpolatingDialog'
import ModflowDialog from './Modflow/ModflowDialog'
import FemwaterDialog from './Femwater/FemwaterDialog'
import MassbalanceDialog from './Massbalance/MassbalanceDialog'
import { SIM_MODEL } from '@/models'

import { mapState } from 'vuex'

export default {
  name: 'SimulationDialog',

  components: {
    InterpolatingDialog,
    ModflowDialog,
    FemwaterDialog,
    MassbalanceDialog
  },

  data: () => ({
    SIM_MODEL,
    dialog: false
  }),

  computed: {
    ...mapState({
      model: state => state.simulation.model
    }),

    vOn() {
      return {
        click: this.open
      }
    }
  },

  methods: {
    open() {
      this.dialog = true
    },
    close() {
      this.dialog = false
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
