<template>
  <v-dialog
    v-model="dialog"
    max-width="1000"
    :retain-focus="false"
    hide-overlay
    no-click-animation
  >
    <template v-slot:activator="{ on, attrs }">
      <slot name="activator" :on="on" :attrs="attrs" />
    </template>
    <v-card
      class="modflow-card"
      :loading="isLoading"
    >
      <v-card-title
        class="
            draggable
            bg-ease
            white--text
            px-5
            py-1
          "
        style="font-size: 1rem;"
      >
        <span v-if="model===SIM_MODEL.MODFLOW">{{ $t('modflow') }}</span>
        <span v-if="model===SIM_MODEL.FEMWATER_MESH">{{ $t('femwater mesh') }}</span>
        <span style="margin-left: 0.5em;">({{ step }}/{{ totalStep }})</span>
        <v-spacer />
        <v-btn
          dark
          icon
          small
          @click="cancel"
        >
          <v-icon small>
            mdi-close
          </v-icon>
        </v-btn>
      </v-card-title>

      <v-card-text>
        <v-stepper
          v-model="step"
          class="elevation-0"
        >
          <v-stepper-items>
            <v-stepper-content step="1">
              <ModflowInputModelFile ref="step1" />
            </v-stepper-content>
            <v-stepper-content step="2">
              <ModflowInputGeoParamFiles ref="step2" />
            </v-stepper-content>
            <v-stepper-content step="3">
              <ModflowOutputs ref="step3" />
            </v-stepper-content>
          </v-stepper-items>
        </v-stepper>
      </v-card-text>

      <v-card-actions>
        <v-btn
          class="action-btn"
          rounded
          :disabled="isLoading"
          @click="init"
        >
          {{ $t('reset') }}
        </v-btn>
        <v-spacer />
        <v-btn
          v-if="step > 1"
          class="action-btn"
          color="primary"
          rounded
          :disabled="isLoading"
          @click="prevStep"
        >
          {{ $t('prev_step') }}
        </v-btn>
        <v-btn
          v-if="step < totalStep"
          class="action-btn"
          color="primary"
          rounded
          :disabled="isLoading || (step === 1 && hasNoPolygon)"
          @click="nextStep"
        >
          {{ $t('next_step') }}
        </v-btn>
        <v-btn
          v-if="step === totalStep"
          class="action-btn"
          color="primary"
          rounded
          :loading="isLoading"
          @click="submit"
        >
          {{ $t('start') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import ModflowInputModelFile from './ModflowInputModelFile.vue'
import ModflowInputGeoParamFiles from './ModflowInputGeoParamFiles.vue'
import ModflowOutputs from './ModflowOutputs.vue'

import { SIM_MODEL } from '@/models'

import { mapGetters, mapState } from 'vuex'

export default {
  name: 'ModflowDialog',

  components: {
    ModflowInputModelFile,
    ModflowInputGeoParamFiles,
    ModflowOutputs
  },

  props: {
    value: {
      type: Boolean,
      default: false
    }
  },

  data: () => ({
    SIM_MODEL,
    show: false,
    isLoading: false,
    step: 1,
    totalStep: 3
  }),

  computed: {
    ...mapState({
      model: state => state.simulation.model
    }),
    ...mapGetters({
      getProjectByRoute: 'projects/getProjectByRoute'
    }),

    dialog: {
      get() {
        return this.show
      },
      set(newVal) {
        this.show = newVal
        this.$emit('input', newVal)
      }
    },

    project() {
      return this.getProjectByRoute(this.$route)
    },
    stepRefs() {
      return Array.from({ length: this.totalStep }, (_, i) => `step${i + 1}`)
    },
    hasNoPolygon() {
      return false
    }
  },

  watch: {
    value: {
      handler(newVal) {
        this.show = newVal
      },
      immediate: true
    },
    model: {
      handler() {
        this.init()
      },
      immediate: true
    },
    '$route.params.lang'(newVal, oldVal) {
      if (newVal !== oldVal && this.$refs.form) {
        this.$refs.form.validate()
      }
    }
  },

  methods: {
    init() {
      this.$store.dispatch('simulation/modflow/initSimData')
        .then(() => {
          this.step = 1
          this.resetValidation()
        })
    },
    resetValidation() {
      this.stepRefs.map(ref => {
        return this.$refs?.[ref]?.resetValidation?.()
      })
    },
    async formValidate(ref) {
      const funValidate = this.$refs?.[ref]?.validate

      if (typeof funValidate !== 'function') {
        return true
      }

      return funValidate()
    },
    validate() {
      return Promise.all(
        this.stepRefs.map(ref => this.formValidate(ref))
      ).then(results => {
        // go to error step when submit
        const firstErrorStep = results.findIndex(v => !v) + 1
        if (firstErrorStep) {
          this.step = firstErrorStep
        }

        return results.every(Boolean)
      })
    },
    prevStep() {
      this.step -= 1
    },
    async nextStep() {
      const ref = `step${this.step}`
      const isValid = await this.formValidate(ref)

      if (!isValid) return

      this.step += 1
    },
    close() {
      this.dialog = false
    },
    cancel() {
      this.close()
    },
    async submit() {
      const isValid = await this.validate()

      if (!isValid) {
        return
      }

      this.isLoading = true
      return this.$store.dispatch('simulation/modflow/start', {
        project: this.project
      })
        .then(() => {
          this.$store.dispatch('snackbar/showSuccess', {
            content: this.$t('success_simulation')
          })
        })
        .catch(error => {
          this.$store.dispatch('snackbar/showError', {
            content: error
          })

          return Promise.reject(error)
        })
        .finally(() => {
          this.isLoading = false
        })
    }
  }
}
</script>

<style lang="scss" scoped>
.modflow-card {
  .v-card__text {
    padding-top: 1rem;

    .v-stepper {
      .v-stepper__content {
        padding: 0;

        ::v-deep .v-stepper__wrapper {
          height: 460px;
        }
      }
    }
  }

  .v-card__actions {
    padding: 1rem;
  }
}

.v-btn.v-btn.action-btn {
  padding: 0.286em 1.743em;
  height: unset;
  font-size: 0.875rem;
  box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.2);
  letter-spacing: normal;
}
</style>
